<template>
    <div id="main">
        <div class="header">
            <div class="box">
                <el-breadcrumb separator="/">
                    <!-- <el-breadcrumb-item>考试信息</el-breadcrumb-item> -->
                    <el-breadcrumb-item :to="{ path: '/' }">
                        考试列表
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>下载准考证</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-main>
            <object
                    type="text/html"
                    height="100%"
                    width="100%"
                    :data="dataurl"
            ></object>
        </el-main>
    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {
                dataurl: "",
            };
        },
        created() {
            let query = this.$route.query;
            // let dataurl = query.dataurl+'&Expires='+query.Expires+'&Signature='+encodeURIComponent(query.Signature);
            let dataurl = query.dataurl;
            let a = dataurl.split('?sign=')[0];
            let b = dataurl.split('?sign=')[1];
            this.dataurl = a + '?sign=' + encodeURIComponent(b);
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #main {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: column;

        .header {
            width: 100%;
            margin-top: 6px;

            .box {
                max-width: 1300px;
                padding: 0 50px;
                margin: 0 auto;
                display: block;
            }
        }

        .el-main {
            flex: 1;
            width: 100%;
            overflow: hidden;
            max-width: 1300px;
            margin: 0px auto;
            padding: 0 50px;
        }
    }
</style>
